@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   
  @font-face {
    font-family: 'FSElliotProBold';
    src: url(/src/assets/fonts/FS_Elliot_ProBold.otf);
  }
}

.header {
  background-image: url('/src/assets/d4a-img/bg-top.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  background-position: center;
}
.footer {
  background-image: url('/src/assets/d4a-img/bg-bottom.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  background-position: bottom;
}
.parent-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: #000845;
}
.content {
  height: -webkit-fill-available;
}
.get-inspired {
  font-family: FSElliotProBold;
  background: -webkit-linear-gradient(
    244.91deg,
    #d4ffec 15.94%,
    #57f2cc 48.55%,
    #45dafb 84.06%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}
.discover-box {
  background: linear-gradient(
    180deg,
    rgba(75, 104, 210, 0.56) -1.11%,
    rgba(37, 27, 100, 0.4) 23.83%,
    #313a5b 99.56%
  );
  box-shadow: 0px -3px 19px 1px rgba(78, 213, 255, 0.65);
  -webkit-box-shadow: 0px -3px 19px 1px rgba(78, 213, 255, 0.65);
  -moz-box-shadow: 0px -3px 19px 1px rgba(78, 213, 255, 0.65);
}
.event-box {
  background: linear-gradient(180.57deg, rgba(0, 8, 69, 0) -1.83%, #000845 99.56%);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-image: url('/src/assets/d4a-img/blur-content-bg.png');
  background-repeat: no-repeat;
}

.event-text {
  background: linear-gradient(90deg, #ffefba 0%, #ffffff 100%);
  font-family: 'Roboto Condensed', 'Roboto';
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.discover {
  width: 35px;
  height: 4.5rem;
  top: 71px;
  left: 0.19px;
  gap: 0px;
  background-image: url('/src/assets/d4a-img/discover-bg.png');
  background-repeat: no-repeat;
}

.date-container {
  width: -webkit-fill-available;
  border-top: 1px solid #fcc456;
  border-bottom: 1px solid #fcc456;
}
.date {
  background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
  font-family: 'Roboto';
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}