@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
   font-family: "NeuePlak";
   src: url("./assets/fonts/NeuePlakTextVariable.ttf");
   /* https: //stackoverflow.com/q/67850101 */
   descent-override: 55%;
}
@font-face {
   font-family: "RobotoBold";
   src: url("./assets/fonts/Roboto-Bold.ttf");
}

* {
   font-family: NeuePlak, sans-serif;
}
body > div:last-child > span + img {
   display: inline !important;
 } 

body {
   background-color: #f5f9fc;
}

.pancake-stack {
   display: grid;
   grid-template-rows: auto auto auto 1fr auto;
}

.pancake-stack-v1 {
   display: grid;
   grid-template-rows: auto 1fr auto;
}

input:checked ~ .dot {
   transform: translateX(100%);
   background-color: #f5f9fc;
}

.error-form {
   border: 2px solid #9f040e !important;
   color: #9f040e;
   font-weight: 700;
   font-size: 14px;
}

.digital4allbody{
   background-color: #000845;
}